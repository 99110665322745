import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql, Link } from "gatsby"
import dayjs from "dayjs"

import "normalize.css"
import "../styles/style.css"
import {
  GithubLinkedIcon,
  TwitterLinkedIcon,
  LinkedInLinkedIcon,
  InstagramLinkedIcon,
  FlickrLinkedIcon,
} from "./SocialIcons"
import logo from "../data/images/logo.svg"

const LocalizedFormat = require("dayjs/plugin/localizedFormat")
dayjs.extend(LocalizedFormat)

const Head = () => {
  return (
    <Helmet>
      <title>iain.fyi</title>
      <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Oswald:wght@700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

const NavHeader = () => {
  return (
    <header>
      <img src={logo} alt="Logo consisting of a screen with an i in HTML tags" />
      <nav>
        <Link to="/photos" className="nav-item">
          Photos
        </Link>

        <Link to="/blog/" className="nav-item">
          Blog
        </Link>

        <Link to="/profile" className="nav-item">
          Profile
        </Link>

        <Link to="/" className="nav-item">
          Home
        </Link>
      </nav>
    </header>
  )
}

const SocialsNav = () => {
  return (
    <>
      <div className="social-icons">
        <TwitterLinkedIcon />
        <InstagramLinkedIcon />
        <LinkedInLinkedIcon />
        <FlickrLinkedIcon />
        <GithubLinkedIcon />
      </div>
    </>
  )
}

// using staticquery here,  to allow graphql from components
const FooterWithStaticQuery = props => {
  return (
    <StaticQuery
      query={graphql`
        query QueryFlickrAccessTime {
          allFlickrPhoto(sort: { fields: datetaken, order: DESC }, limit: 1) {
            nodes {
              localImage {
                accessTime
              }
            }
          }
        }
      `}
      render={data => <Footer data={data} {...props} />}
    />
  )
}

const Footer = ({ data }) => {
  return (
    <>
      <footer>
        <SocialsNav />
        <p>Last deployed: {dayjs(data.allFlickrPhoto.nodes[0].localImage.accessTime).format("LLL")}</p>
        <p>© 2021 Iain James Pritchard (@iainjames9). All rights reserved.</p>
      </footer>
    </>
  )
}

export default function Layout({ children }) {
  return (
    <>
      <Head />
      <NavHeader />
      {children}
      <FooterWithStaticQuery />
    </>
  )
}
