import React from "react"

export const Twitter = ({ children }) => {
  return <a href="https://www.twitter.com/iainjames9">{children}</a>
}

export const Github = ({ children }) => {
  return <a href="https://www.github.com/iainjp">{children}</a>
}

export const LinkedIn = ({ children }) => {
  return <a href="https://www.linkedin.com/in/iainjames9">{children}</a>
}

export const Instagram = ({ children }) => {
  return <a href="https://www.instagram.com/iainjames9">{children}</a>
}

export const Flickr = ({ children }) => {
  return <a href="https://www.flickr.com/photos/iainjames9/">{children}</a>
}

export const Skyscanner = () => {
  return <a href="https://www.skyscanner.com/">Skyscanner</a>
}

export const ClickTravel = () => {
  return <a href="https://www.clicktravel.com/">ClickTravel</a>
}

export const Unity = () => {
  return <a href="https://unity.com/">Unity</a>
}

export const Birdi = () => {
  return <a href="https://www.hibirdi.com/">Bird.i</a>
}
