import React from "react"
import { Github, Twitter, Instagram, LinkedIn, Flickr } from "./Links"

export const GithubLinkedIcon = () => {
  return (
    <Github>
      <img src="/github.svg" alt="Github icon with link to my profile" />
    </Github>
  )
}

export const LinkedInLinkedIcon = () => {
  return (
    <LinkedIn>
      <img src="/linkedin.svg" alt="LinkedIn icon with link to my profile" />
    </LinkedIn>
  )
}

export const TwitterLinkedIcon = () => {
  return (
    <Twitter>
      <img src="/twitter.svg" alt="Twitter icon with link to my profile" />
    </Twitter>
  )
}

export const InstagramLinkedIcon = () => {
  return (
    <Instagram>
      <img src="/instagram.svg" alt="Instagram icon with link to my profile" />
    </Instagram>
  )
}

export const FlickrLinkedIcon = () => {
  return (
    <Flickr>
      <img src="/flickr.svg" alt="Flickr icon with link to my profile" />
    </Flickr>
  )
}
